import React from "react";
import "./Home.css";
import SignUp from "../components/auth/signUp.js";

import StockDrawdown from "../components/StockDrawdown.js";

const Home = () => {
  return (
    <div className="home">
      <div className="index-drawdowns">
        <div className="page-header">
          <h1 className="h-1">Index Drawdowns</h1>
        </div>
        <div className="stock-drawdown-container">
          <StockDrawdown index="S&P500" ticker="SPY"></StockDrawdown>
          <StockDrawdown index="Nasdaq 100" ticker="QQQ"></StockDrawdown>
          <StockDrawdown index="Dow Jones" ticker="DIA"></StockDrawdown>
          <StockDrawdown index="Russell 2000" ticker="IWM"></StockDrawdown>
        </div>
      </div>
      <div className="my-portfolio">
        <h1 className="h-1">My Portfolio</h1>
      </div>
      <div>
        <SignUp></SignUp>
      </div>
    </div>
  );
};

export default Home;
